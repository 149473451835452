.site-footer {
	.newsletter-signup {
		padding-bottom: 0;

		form {
			margin-top: 16rem;

			label {
				display: none;
			}
		}

		.btn {
			.text {
				@include font-primary-bold;
			}
		}

		.thirst-quenchers {
			margin-top: 15rem;
		}
	}

	.site-map {
		padding-top: 2rem;

		.text-columns {
			padding: 0 12rem;
		}

		.column {
			.row {
				&:not(:first-child) {
					margin-top: 4rem;
				}

				a {
					color: inherit;

					@include font-primary-bold;

					&[target='_blank'] {
						&::after {
							content: '\f08e';

							margin-left: 2.5rem;

							@include font-icon;
						}
					}
				}

				.description {
					margin-top: 4rem;

					@include font-primary-light;
				}
			}
		}
	}

	.footer-right {
		text-align: right;
	}

	.footer-social {
		.icon {
			display: inline-block;
			width: 5rem;

			color: white;

			&:not(:first-child) {
				margin-left: 3rem;
			}

			svg {
				fill: currentColor;
			}
		}
	}

	.footer-bcorp {
		margin-top: 8rem;

		.icon {
			display: inline-block;
			width: 15rem;

			color: white;

			svg {
				fill: currentColor;
			}
		}
	}

	.footer-copyright {
		margin-top: 8rem;

		color: rgba(white, 0.6);

		font-size: 0.85em;
	}

	@media screen and (max-width: $bp-tablet) {
		text-align: center;

		[itemprop='telephone'] {
			color: $color-thirst-link;

			text-decoration: underline;
			@include font-primary-black;
		}

		.site-map {
			font-size: 4.5rem;

			.text-columsn {

			}

			.column {
				.row {
					&:not(:first-child) {
						margin-top: 4rem;
					}
				}
			}
		}

		.footer-right {
			margin-top: 12rem;

			text-align: center;
		}

		.footer-bcorp {
			.body {
				font-size: 4.5rem;
			}

			.icon {
				margin-top: 4rem;
			}
		}

		.footer-copyright {
			font-size: 3.5rem;
		}
	}
}