.tag-list {	
	margin-left: -1.5rem; 
	display: flex;
	flex-wrap: wrap;

	& > .list-item {
		display: inline-block;
		flex: 0 0 auto;
		padding: 0 4rem;
		margin: 1.5rem;

		background: $color-thirst-mist;

		@include font-primary-regular;
		font-size: 4rem;
	}
}