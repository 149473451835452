.card {
	&.card-dark {
		color: white;

		.card-content {
			.body-large {
				color: rgba(white, 0.8);

				@include font-primary-medium;
			}
		}
	}

	.card-image {
		position: relative;

		width: 100%;

		&.-square {
			.card-image-inner {
				padding-bottom: 100%;
			}
		}

		.card-image-inner {
			width: 100%;
			padding-bottom: 75%;

			background: {
				color: rgba(black, 0.2); 
				image: url('../img/placeholder.svg');
				position: center;
				size: cover;
				repeat: no-repeat;
			}
		}

		.card-image-logo {
			position: absolute;
			top: 10rem;
			left: 50%;
			transform: translateX(-50%);

			display: block;
			width: 75%;

			img {
				width: 100%;

				filter: drop-shadow(0 0.5rem 1.5rem rgba(black, 0.35));
			}
		}
	}

	.card-content {
		padding: 7rem;
	}

	.link {
		display: inline-block;
		margin-top: 10rem;
	}

	&.card-wide {
		background: white;

		.body-large {
			@include font-primary-light;
		}

		@media screen and (min-width: ($bp-tablet + 1px)) {
			display: flex;
			width: 100%;

			& > * {
				display: inline-block;
				flex: 0 0 auto;
			}

			& > a {
				display: flex;
				width: 100%;

				& > * {
					display: inline-block;
					flex: 0 0 auto;
				}
			}

			// .card-link {
			// 	display: flex;
			// 	width: 100%;

			// 	& > * {
			// 		display: inline-block;
			// 		flex: 0 0 auto;
			// 	}
			// }

			.card-image {
				width: 40%;

				.card-image-inner {
					padding-bottom: 69%;
					min-height: 100%;
				}
			}

			.card-content {
				flex: 1 1 auto;
				padding: 10rem;

				.body-large {
					@include font-primary-light;
				}
			}

			&.-with-list {
				background: rgba($color-thirst-mist, 0.4);

				.card-image {
					width: 30%;

					.card-image-inner {
						padding-bottom: 100%;
					}
				}

				.card-content {
					padding: 12rem 15rem;
				}
			}
		}
	}

	&.card-small {
		.card-image {
			.card-image-inner {
				padding-bottom: 100%;
			}
		}

		.card-content {
			padding: 5rem;

			.body {
				margin-top: 3rem;

				font-size: 4rem;
				@include font-primary-regular;
			}
		}
	}

	@media screen and (max-width: $bp-tablet) {
		&:not(:first-child) {
			margin-top: 5rem;
		}

		.card-image {
			display: flex;
			align-items: center;
			max-height: 100rem;
			overflow: hidden;
		}
	}
}

.card-list {
	&.-alternate {
		.card-row {
			&:nth-child(2n) {
				.card {
					flex-direction: row-reverse;
				}
			}
		}
	}
}