$color-thirst-lime: #9BCC50;

$color-thirst-navy: #2F3755;
$color-thirst-slate: #3F4967;

$color-thirst-sky: #27C0F1;

$color-thirst-mist: #E6EBEE;

$color-thirst-charcoal: #333333;



$color-thirst-primary: $color-thirst-lime;

$color-thirst-body: $color-thirst-charcoal;

$color-thirst-link: $color-thirst-sky;