.site-nav {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 10;
	transform: rotateZ(0);

	height: 30rem;
	padding: 0 5rem;

	transition: all $transition;

	background: transparent;
	color: white;

	.inner {
		height: 100%;
	}

	.-nav-fixed & {
		height: 20rem;

		box-shadow: 0 -2px 0 0 rgba($color-thirst-mist, 0.2) inset;
		background: $color-thirst-navy;
	}

	.nav-list {
		position: absolute;
		top: 50%;
		transform: translate3d(0, -51%, 0);
		
		&.nav-left {
			left: 0;
		}

		&.nav-right {
			right: 0;
		}

		.nav-item {
			position: relative;

			display: inline-block;
			vertical-align: middle;
			height: auto;

			@include font-primary-medium;
			font-size: 0.85em;

			&:not(:first-child) {
				margin-left: 8rem;
			}

			&:not(.site-logo):not(:last-child) {
				&.-active, &:hover {
					a {
						&::after {
							opacity: 1;
						}
					}
				}

				a:not(.btn) {
					&:focus {
						&::after {
							opacity: 1;
						}
					}
				}
			}

			&.-active {
				// pointer-events: none;

				.btn {
					// box-shadow: 0 0 0 1px $color-thirst-primary;
					background: white;
					color: $color-thirst-navy;
				}
			}

			a {
				transform: rotateZ(0);

				display: block;
				padding: 3rem 0;

				&:not(.btn-logo) {
					&:focus {
						outline: 0;
						border: 0;
					}

					&:not(.btn) {
						margin: 0 2rem;
					}
				}
				

				&.btn {
					padding: {
						left: 8rem;
						right: 8rem;
					}
				}
				
				&::after {
					content: '';

					position: absolute;
					bottom: 0;
					left: 0;

					display: block;
					width: 100%;
					height: 1px;

					background: currentColor;
					opacity: 0;

					transition: all $transition;
				}
			}

			.btn-default {
				vertical-align: middle;
				padding: 3.5rem 6rem;
				height: auto;

				@include font-primary-medium;
				font-size: 1em;
				line-height: 1em;
			}
		}

		.site-logo {
			width: 50rem;

			a {
				padding: 0;

				// &:focus {
				// 	svg {
				// 		transform: scale(1.05);
				// 	}
				// }
			}

			svg {
				vertical-align: middle;

				fill: currentColor;

				transition: all $transition, color ($transition / 4);
			}
		}
	}

	.nav-list-mobile {
		position: absolute;
		top: 0;

		flex-direction: column;
		justify-content: center;
		width: 100%;
		height: 100vh;
		padding-top: 17.5rem;

		background: white;
		color: $color-thirst-navy;

		text-align: center;

		transition: all $transition;

		.nav-section {
			padding: 5rem 0;

			.nav-item {
				padding: 4rem;

				a {
					font-size: 1.5em;
				}
			}
		}

		.nav-top {
			position: relative;

			&::after {
				content: '';

				position: absolute;
				bottom: 0;
				left: 50%;
				transform: translateX(-50%);

				display: block;
				width: 35rem;
				height: 1px;

				background: $color-thirst-navy;
				opacity: 0.2;
			}

			.nav-item {
				font-size: 6rem;
				@include font-primary-black;
			}
		}

		.nav-bottom {
			.nav-item {
				font-size: 4.5rem;
				@include font-primary-light;
			}
		}
	}

	@media screen and (max-width: $bp-tablet) {
		height: 17.5rem;
		padding: 0;

		transition: background $transition;;

		.-nav-fixed & {
			height: 17.5rem;
		}

		.inner {
			position: absolute;
			top: 0;
			z-index: 10;
		}

		.site-logo {
			left: 6rem;
			transform: translateY(-0.75rem);
		}

		.nav-list-mobile {
			display: flex;
		}
	}

	@media screen and (max-width: $bp-mobile) {
		position: fixed;
		
		& > .inner {
			box-shadow: 0 -2px 0 0 rgba($color-thirst-mist, 0.2) inset;
			background: $color-thirst-navy;
		}

		.nav-list-mobile {
			.nav-section {
				.nav-item {
					a {
						font-size: 1em;
					}
				}
			}
		}
	}
}

@media screen and (max-width: $bp-tablet) {
	.btn-mobile-nav {
		position: fixed;
		right: 6rem;
		top: 8.75rem;
		transform: translateY(-50%);
		z-index: 20;

		width: 6rem;
		height: 5rem;
		padding: 0;
		margin: 0;

		transition: color ($transition / 2);

		$btn-transition: ($transition / 4);

		.btn-part {
			position: absolute;
			top: 2.5rem;
			transform: translateY(-50%);

			height: 0.75rem;
			width: 100%;
			background: transparent;

			transition: all $btn-transition;

			&.-exit {
				&:before, &:after {
					content: '';

					position: absolute;
					top: 0;
					left: 0;

					display: block;
					height: 100%;
					width: 100%;

					background: $color-thirst-navy;
					opacity: 1;

					transition: all $btn-transition $btn-transition;
				}

				&:before {
					transform: rotate(45deg);
				}

				&:after {
					transform: rotate(-45deg);
				}
			}
		}

		&.-expanded {
			z-index: 19;

			.btn-part {
				transition: all $btn-transition $btn-transition;

				background: white;

				&:first-child {
					transform: translateY(-2.5rem) translateY(-50%);
				}

				&:last-child {
					transform: translateY(2.5rem) translateY(-50%);
				}

				&.-exit {
					&:before, &:after {
						transform: rotate(0);

						opacity: 0;

						transition: all $btn-transition 0s;
					}
				}
			}

			& + .site-nav {
				.nav-list-mobile {
					visibility: hidden;
					opacity: 0;
				}
			}
		}

		&:not(.-expanded) + .site-nav {
			color: $color-thirst-navy;
			
			.inner {
				background: transparent;
				box-shadow: none;
			}

			.nav-list-mobile {
				visibility: visible;
				opacity: 1;
			}
		}
	}
}