.section {
	position: relative;
	transform: rotateZ(0);

	width: 100%;
	padding: 20rem 0 45rem 0;

	background: white;
	color: $color-thirst-slate;

	&.-angle {
		padding-top: 15rem;

		&::before {
			content: '';

			position: absolute;
			top: 0;
			left: 50%;

			display: block;
			width: 105%;
			padding-bottom: 7.5%;

			background: white;
			filter: drop-shadow(0 0 0 transparent);
		}

		&.-left {
			&::before {
				transform: translate(-50%, -50%) rotate(-4deg) rotateZ(0);
			}
		}

		&.-right {
			&::before {
				transform: translate(-50%, -50%) rotate(4deg) rotateZ(0);
			}
		}
	}

	&.-flat-bottom {
		padding-bottom: 20rem;
	}

	&.-pad-bottom {
		padding-bottom: 52.5rem;
	}

	&.-pad-top {
		padding-top: 27.5rem;
	}

	&.section-dark {
		background: $color-thirst-navy;
		color: white;

		&::before {
			background: $color-thirst-navy;
		}

		.headline {
			color: $color-thirst-primary;
		}

		p, .body {
			color: rgba(white, 0.8);

			@include font-primary-medium();
		}

		h1 + p, h2 + p {
			color: white;
		}

		.body-large {
			@include font-primary-regular();
		}
	}

	&.section-light {
		background: $color-thirst-mist;

		&::before {
			background: $color-thirst-mist;
		}
	}

	&.section-brand {
		background: $color-thirst-primary;

		&::before {
			background: $color-thirst-primary;
		}

		.headline {
			color: $color-thirst-navy;
		}
	}

	&.header {
		padding-top: 50rem;

		background: transparent;

		&.-parallax {
			.section-background {
				height: 110%;
				width: 100%;
			}
		}
	}

	.section-background {
		position: absolute;
		top: 0;
		left: 0;

		height: 100%;
		width: 100%;

		background: $color-thirst-navy;

		.section-background-inner {
			width: 100%;
			height: 100%;

			background: {
				size: cover;
				position: center;
			}
			opacity: 0.2;
		}
	}

	.inner {
		position: relative;

		width: 100%;
		@include max-width;
		margin: 0 auto;
		padding: 0 5rem;
	}
}

.header + .section {
	&::after {
		content: '';

		position: absolute;
		top: 0;
		left: 50%;
		transform: translateY(-103%);

		width: 0;
		height: 0;

		// background: $color-thirst-mist;

		border: {
			top: 3.5vw solid transparent;
			bottom: 3.5vw solid transparent;
			right: 50vw solid $color-thirst-mist;
		}
	}

	&.section-light {
		&::after {
			border-right: 50vw solid rgba(white, 0.6);
		}
	}
}

.centered-content {
	max-width: 190rem;
	margin: 0 auto;

	text-align: center;

	& + * {
		margin-top: 20rem;
	}
}

.card-row {
	align-items: stretch;

	&[data-col='3'] {
		$margin: 7.5rem;

		.card:not(.card-wide) {
			width: calc(((100% - #{$margin} * 2)/ 3));

			&:not(:first-child) {
				margin-left: calc(#{$margin} - 1px);
			}
		}
	}

	&[data-col='4'] {
		$margin: 6rem;

		.card:not(.card-wide) {
			width: calc(((100% - #{$margin} * 3)/ 4));

			&:not(:first-child) {
				margin-left: calc(#{$margin} - 1px);
			}
		}
	}

	.card:not(.card-wide) {
		display: inline-block;
		flex: 0 1 auto;
	}

	& + .card-row {
		margin-top: 15rem;
	}
}

.section-buttons {
	perspective: 1000px;

	margin-top: 16rem;

	text-align: center;
}

.text-columns {
	&[data-col='2'] {
		.column {
			padding: 0 14rem 0 7rem;
		}
	}

	&[data-col='3'] {
		.column {
			padding: 0 7rem 0 7rem;
		}
	}

	.column {
		.row {
			&:not(:first-child) {
				margin-top: 10rem;
			}
		}
	}

	h3 {
		font-size: 1.5em;
	}
}

.body, p {
	color: $color-thirst-body;

	& + p, & + .body {
		margin-top: 4rem;
	}
}

h1 {
	& + * {
		margin-top: 8rem;
	}
}

h2 {
	& + * {
		margin-top: 16rem;
	}

	& + .body-large {
		margin-top: 8rem;
	}
}

h3, h4 {
	& + * {
		margin-top: 4rem;
	}
}

a {
	color: inherit;
}

.body-large {
	color: inherit;
}

.link {
	position: relative;

	display: inline-block;

	color: $color-thirst-link;
	outline: 0;
	border: 0;

	text-decoration: none;
	line-height: 1.625em;

	&:hover, &:focus {
		&::before {
			opacity: 1;
		}
	}

	&::before {
		content: '';

		position: absolute;
		bottom: -1px;
		left: 0;

		display: block;
		width: 100%;
		height: 1px;

		background: currentColor;
		opacity: 0;

		transition: all $transition;
	}
}

.list {
	li {
		position: relative;

		padding-left: 10rem;

		&:not(:first-child) {
			margin-top: 4rem;
		}

		&::before {
			content: '';

			position: absolute;
			top: 3.5rem;
			left: 3.5rem;

			display: block;
			width: 2rem;
			height: 2rem;

			background: currentColor;
			border-radius: 100%;
		}
	}
}

.image-grid {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	.image-box {
		$margin: 6rem;

		display: inline-block;
		flex: 0 1 auto;

		@media screen and (min-width: ($bp-tablet + 1px)) {
			&:nth-child(5n + 1), &:nth-child(5n + 2), &:nth-child(5n + 3) {
				width: calc((100% - #{$margin * 2}) / 3);

				.image {
					padding-bottom: 75%;
				}
			}

			&:nth-child(5n + 4), &:nth-child(5n) {
				width: calc((100% - #{$margin}) / 2);
			}

			&:not(:nth-child(1)), &:not(:nth-child(2)), &:not(:nth-child(3)) {
				margin-top: $margin;
			}
		}

		&.info-box {
			padding: 7rem;
			background: $color-thirst-navy;
			color: white;

			.info-box-inner {
				position: relative;

				height: 100%;
				width: 100%;
			}

			.body {
				color: rgba(white, 0.8);
				@include font-primary-regular;
			}

			.btn {
				position: absolute;
				bottom: 0;
				left: 0;

				padding: 0 6rem;

				line-height: 12.5rem;
			}
		}

		.image {
			position: relative;

			width: 100%;
			padding-bottom: 56.25%;
			overflow: hidden;

			background: {
				size: cover;
				position: center;
			}
		}
	}
}

.logo-grid {
	width: 100%;

	.logo-list {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		width: 100%;

		.client-logo {
			width: 17.5%;
			flex: 0 0 auto;

			&:not(:nth-child(4n + 1)) {
				margin-left: 10%;
			}

			.logo {
				padding-bottom: 75%;

				background: {
					size: contain;
					position: center;
					repeat: no-repeat;
				}
				opacity: 0.65;
			}
		}
	}
}

.thirst-map {
	position: relative;

	width: 100%;
	height: 100rem;

	& + * {
		margin-top: 12rem;
	}

	.map-overlay {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 100000;

		width: 100%;
		height: 100%;

		background: transparent;

		.-using-map & {
			display: none;
		}
	}

	.gmap {
		width: 100%;
		height: 100%;

		border: 0;
	}
}

.btn-reveal {
	transform: translateZ(0);
}

.reveal {
	transform: translateZ(0);

	height: 0;
	overflow: hidden;

	transition: height ($transition * 2);
}

.-mobile {
	display: none;
}

@media screen and (max-width: $bp-tablet) {
	.section {
		padding: 10rem 0 22.5rem 0;

		&.-flat-bottom {
			padding-bottom: 10rem;
		}

		&.-angle {
			padding-top: 15rem;
		}

		&.header {
			padding-top: 27.5rem;

			.body-large {
				font-size: 6.75rem;
			}
		}

		.inner {
			padding: 0 2rem;

			.centered-content {
				padding: 0 3rem;
			}
		}
	}

	h1, h2 {
		& + * {
			margin-top: 6rem;
		}
	}

	.-mobile {
		display: block;

		&.-mobile-inline {
			display: inline-block;
		}
	}

	.-desktop {
		display: none !important;
	}

	.centered-content + * {
		margin-top: 16rem;
	}

	.card-row {
		& + .card-row {
			margin-top: 8rem;
		}

		h2 + & {
			margin-top: 10rem;
		}

		&[data-col='3'] {
			.card:not(.card-wide) {
				width: 100%;

				&:not(:first-child) {
					margin-left: 0;
				}
			}
		}

		&[data-col='4'] {
			.card:not(.card-wide) {
				width: calc(50% - 2.5rem);

				&:nth-child(2n) {
					margin-left: calc(5rem - 1px);
					margin-top: 0;
				}

				&:not(:nth-child(2n)) {
					margin-left: 0;
					margin-top: 0;
				}
			}

			.card-content {
				padding: 6rem;
			}
		}
	}

	.text-columns {
		margin-top: 10rem;

		&[data-col='2'] {
			.column {
				padding: 0 7rem;
			}
		}

		.column {
			&:not(:first-child) {
				margin-top: 10rem;
			}
		}
	}

	.list {
		li {
			padding-left: 4rem;

			&::before {
				left: 0;
			}
		}
	}

	.section-buttons {
		margin-top: 8rem;
	}

	.logo-grid {
		.logo-list {
			justify-content: space-around;

			.client-logo {
				width: 40%;

				&:not(:nth-child(4n + 1)) {
					margin-left: 0;
				}
			}
		}
	}

	.image-grid {
		.image-box {
			width: 100%;
			margin-top: 2rem;

			&.info-box {
				margin-top: 0;

				.btn {
					position: relative;

					margin-top: 6rem;
				}
			}
		}
	}
}