@import url('https://fonts.googleapis.com/css?family=Lato:300,400,500,700,900');

@font-face {
	font-family: "fontello";
	src: url('../fonts/fontello.woff2') format('woff2');
	src: url('../fonts/fontello.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}

$font-primary: 'Lato', 'Arial', sans-serif;
$font-icon: 'fontello', 'Arial', sans-serif;

@mixin font-primary-light() {
	font-family: $font-primary;
	font-weight: 300;
}

@mixin font-primary-regular() {
	font-family: $font-primary;
	font-weight: 400;
}

@mixin font-primary-medium() {
	font-family: $font-primary;
	font-weight: 500;
}

@mixin font-primary-bold() {
	font-family: $font-primary;
	font-weight: 700;
}

@mixin font-primary-black() {
	font-family: $font-primary;
	font-weight: 900;
}

@mixin font-icon() {
	font-family: $font-icon;
}
