.page-case-study {
	.header {
		h1 {
			font-size: 8.75rem;

			.text {
				position: relative;

				display: inline-block;

				&::before, &::after {
					content: '';

					position: absolute;
					top: 50%;

					display: block;
					width: 12.5rem;
					height: 0.5rem;

					background: $color-thirst-primary;
				}

				&::before {
					left: 0;
					transform: translate(-150%, -50%);
				}

				&::after {
					right: 0;
					transform: translate(150%, -50%);
				}
			}
		}

		.body-large {
			@include font-primary-light;
			font-size: 15rem;
			line-height: 1.2em;
		}
	}

	@media screen and (max-width: $bp-tablet) {
		.header {
			h1 {
				font-size: 7rem;
			}

			.body-large {
				font-size: 11.25rem;
			}
		}
	}
}

.page-case-study {
	.page-container {
		.section {
			.headline {
				& + * {
					margin-top: 10rem;
				}
			}
		}
	}
}


.case-study {
	.subsection {
		&:not(:first-child) {
			margin-top: 20rem;
		}

		.body {
			padding: 0 22rem;

			text-align: left;

			p {
				&:not(:first-child) {
					margin-top: 10rem;
				}
			}

			ul {
				list-style-type: disc;
				padding-left: 5rem;

				li {
					margin-top: 3rem;
				}
			}
		}
	}

	.caption {
		margin-top: 4rem;

		opacity: 0.6;

		@include font-primary-regular;
		font-size: 4.5rem;
		font-style: italic;
	}

	.takeaway-list {
		// display: flex;
		// justify-content: center;

		// .list-item {
		// 	flex: 0 0 auto;
		// 	width: 92.5rem;
		// 	padding: 8rem;

		// 	background: $color-thirst-mist;

		// 	&:not(:first-child) {
		// 		margin-left: 7.5rem;
		// 	}

		// 	.body {
		// 		padding: 0;

		// 		text-align: center;
		// 	}
		// }

		.list-item {
			text-align: left;

			&:not(:first-child) {
				margin-top: 4rem;
			}

			strong {
				color: $color-thirst-slate;
			}

			.text {
				margin-left: 2rem;
			}
		}
	}

	.deliverables-list {
		padding: 0 20rem;
	}
}

.head-image {
	& + * {
		margin-top: 16rem;
	}
}

.pull-quote {
	padding: 12rem 0;

	background: $color-thirst-mist;
}

.quote {
	position: relative;

	text-align: left;

	// &::before {
	// 	content: '';

	// 	position: absolute;
	// 	left: -4rem;
	// 	top: 0;
	// 	transform: translateX(-100%);

	// 	display: block;
	// 	width: 3rem;
	// 	height: 100%;

	// 	background: {
	// 		image: url('../img/border-wave.svg');
	// 		size: 100%;
	// 		repeat: repeat-y;
	// 		position: center top;
	// 	}
	// }

	.quote-body {
		position: relative;

		@include font-primary-light;
		font-size: (4em / 3);
		
		&::before {
			content: '\201c';

			position: absolute;
			left: 0;
			transform: translateX(-100%);
		}

		&::after {
			content: '\201d';
		}
	}

	.attribution {
		margin-top: 4rem;

		color: $color-thirst-navy;
		
		@include font-primary-black;
		font-size: 1.25em;
	}
}

.impact {
	margin-top: 10rem;

	.stats {
		display: flex;
		justify-content: center;

		text-align: center;

		.stat-item {
			display: flex;
			flex-direction: column;
			justify-content: center;
			width: 42.5rem;
			height: 42.5rem;
			margin: 0 4rem;

			background: rgba(white, 0.85);
			border-radius: 100%;
			color: $color-thirst-navy;

			&.-dec {
				.stat-number {
					&::before {
						transform: rotate(180deg) translateY(20%);
					}
				}
			}
		}

		.stat-description {
			@include font-primary-regular;
		}

		.stat-number {
			margin-top: 3rem;

			font-size: 1.75em;
			@include font-primary-black;

			&::before {
				content: '';

				transform: translateY(-20%);

				border: {
					left: 2.4rem solid transparent;
					right: 2.4rem solid transparent;
					bottom: 3.6rem solid currentColor;
				}

				display: inline-block;
				margin-right: 2rem;
				vertical-align: middle;
			}
		}
	}

	.quote {	
		margin-top: 10rem;
	}
}

.page-nav {
	position: relative;

	height: 8rem;
	margin-top: 16rem;

	.nav-right {
		position: absolute;
		top: 0;
		right: 0;

		display: inline-block;
		max-width: 30%;
		overflow: hidden;

		// white-space: nowrap;
		text-overflow: ellipsis;
		@include font-primary-black;
		text-align: right;
	}
}

.image {
	max-width: 100%;

	&.-full-width {
		width: 100%;
	}
}

@media screen and (max-width: $bp-tablet) {

	.page-case-study {
		.page-container {
			.section {
				.headline {
					padding: 0 6rem;

					& + * {
						margin-top: 6rem;
					}
				}

				.body {
					padding: 0 6rem;

					p {
						&:not(:first-child) {
							margin-top: 4rem;
						}
					}
				}
			}
		}
	}

	.case-study {
		padding-bottom: 16rem !important;

		.inner {
			.centered-content {
				padding: 0;
			}
		}

		.subsection {
			&:not(:first-child) {
				margin-top: 10rem;
			}
		}

		.subsection-image {
			padding: 0;
		}

		.caption {
			margin-top: 2rem;
		}

		.pull-quote {
			padding: 6rem;
			padding-left: 12rem;
		}

		.takeaway-list {
			flex-wrap: wrap;

			.list-item {
				width: 100%;

				&:not(:first-child) {
					margin-left: 0;
					margin-top: 2rem;
				}
			} 
		}
	}

	.section-impact {
		margin-top: 0 !important;
		padding-top: 0 !important;

		&::before {
			display: none !important;
		}

		.inner {
			padding: 0;

			.head-image-container {
				padding: 0;
			}
		}

		.head-image {
			margin-top: 0 !important;
		}

		.body {
			padding: 0 6rem;

			p {
				&:not(:first-child) {
					margin-top: 4rem;
				}
			}
		}

		.impact {
			.stats {
				flex-wrap: wrap;
			}

			.stat-item {
				flex: 0 0 auto;
				margin: 0 2rem;
			}

			.quote {
				padding: 0 2rem 0 11rem;

				&::before {
					left: 7rem;
				}
			}
		}
	}

	.page-nav {
		height: auto;

		text-align: center;

		.nav-right {
			position: relative;

			display: block;
			max-width: none;
			margin-top: 6rem;
		
			text-align: center;
		}
	}
}

body:not(.page-case-study) {
	.case-study {
		h2 {
			& + * {
				margin-top: 10rem;
			}
		}

		h3 {
			font-size: 7.5rem;
			line-height: 1.33em;

			&:not(:first-child) {
				margin-top: 10rem;
			}

			& + * {
				margin-top: 6rem;
			}
		}
	}
}

@media screen and (max-width: $bp-mobile) {
	.case-study {
		.deliverables-list {
			text-align: center;
		}
	}
}