.btn {
	padding: 0;
	margin: 0;

	background: transparent;
	border: 0;
	outline: 0;
	color: inherit;

	text-decoration: none;

	transition: all $transition;

	&.btn-default {
		display: inline-block;
		min-width: 0;
		padding: 0 16rem;
		height: 12.5rem;

		box-shadow: 0 0 0 1px $color-thirst-navy inset;
		color: $color-thirst-navy;

		font-size: 0.85em;
		@include font-primary-medium;

		&.-reverse {
			color: white;
			box-shadow: 0 0 0 1px white inset;

			&:hover, &:focus {
				background: white;
				color: $color-thirst-navy;
			}
		}

		&:hover, &:focus {
			color: white;
			background: $color-thirst-navy;
		}
	}

	&.btn-cta {
		position: relative;
		transform-style: preserve-3d;
		transform-origin: center center;

		display: inline-block;
		min-width: 90rem;
		height: 12.5rem;

		box-shadow: 0 0 0 1px currentColor inset;

		font-size: 0.85em;
		@include font-primary-black();

		transition: all $transition;

		&.-dark {
			background: white;
			color: $color-thirst-navy;
			box-shadow: none;

			&::after {
				background: $color-thirst-navy;
				color: white;
			}
		}

		&.-reverse {
			color: white;

			&::after {
				background: white;
				color: $color-thirst-navy;
			}
		}

		&:hover, &:focus {
			// transform: rotateX(-90deg) translate3d(0, 0, 6.25rem);

			color: white;

			// transition: all $transition;

			// &::before, &::after {
			// 	transition: opacity $transition;
			// }

			&::before {
				opacity: 0;
			}

			&::after {
				opacity: 1;
			}
		}

		&::before, &::after {
			content: attr(aria-label);

			display: block;
			width: 100%;
			height: 100%;

			line-height: 12.5rem;

			// transition: opacity $transition ($transition / 2);

			transition: opacity $transition;
		}

		&::after {
			position: absolute;
			top: 0;
			// transform: translate3d(0, -100%, 0) scale(1, -1) rotateX(90deg);
			// transform-origin: center bottom;

			background: $color-thirst-navy;
			// color: white;
			opacity: 0;
		}
	}

	@media screen and (max-width: $bp-tablet) {
		&.btn-cta {
			min-width: 77.5rem;
		}
	}
}