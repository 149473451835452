form {
	text-align: left;
}

.form-group {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-center;
	margin-top: 4rem;

	.btn-submit {
		flex: 0 0 auto;
		margin-left: 2rem;
	}
}

label {
	display: block;
	padding: 0 5rem;

	font-size: 0.75em;
	@include font-primary-medium;
}

input {
	height: 12.5rem;
	padding: 4rem 5rem;
	flex: 1 1 auto;

	background: rgba($color-thirst-navy, 0.08);
	border: 0;
	outline: 0;

	vertical-align: top;
}

@media screen and (max-width: $bp-tablet) {
	.form-group {
		.btn-submit {
			width: 100%;
			margin-left: 0;
			margin-top: 6rem;
		}
	}
}